import React, { useState,useRef,useEffect } from "react";
import styles from "./bible.module.css";
import Loader from "../../Loader.js";
import { bibleAPi } from "../../controller/dbcontroller.ts";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BiblePage() {
  const [inputValue, setInputValue] = useState("");
  const [responseValue, setResponseValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const handleButtonClick = async () => {
    setTheResponse("");
    setLoading(true);
    console.log("button clicked -> ", inputValue);
    var userData = localStorage.getItem("userName");

    var apiResponse = await bibleAPi(inputValue, userData);
    if(apiResponse === "invalid Subscription") {
      toast.error("Subscription has expired");
      navigate("/buynow");
    }
    else{
      setTheResponse(apiResponse);
    }
    
    
    // setTheResponse(apiResponse);
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleButtonClick();
    }
  };

  const setTheResponse = (resValue) => {
    setResponseValue(resValue);
  };

  const handleBackClick = async () => {
    navigate("/");
  };

  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleprivacyClick = (event) => {
    navigate("/privacypolicy");
  };
  const handleTermsClick = (event) =>{
    navigate("/termsandcondition");
  }
  const handleCancellationClick = (event) =>{
    navigate("/cancellationpolicy");
  }
  const handleContactUsClick = (event) =>{
    navigate("/contactUs");
  }

  const handlebuyNowClick = (event) =>{
    navigate("/buynow");
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, []);

  return (
    <section className={styles.bibleSection}>
      <ToastContainer/>
      <div className={styles.container}>
        <p className={styles.topContent}>
          'Ask and it will be given to you; seek and you will find; knock and
          the door will be opened to you.'
        </p>
        <p className={styles.topContentTwo}>- Matthew 7:7</p>
        <div className={styles.inputContainer}>
          <h3>Instructions</h3>
          <p>
            Type your <strong>Question</strong> and click on{" "}
            <strong>Submit</strong>
          </p>
          <aside>
            <p>Note: All your answers will be based on the Bible.</p>
          </aside>
        </div>
        <div className={styles.inputContainer}>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
          <div className={styles.submitButtonDiv}>
            <button
              className={styles.submitButton}
              onClick={handleButtonClick}
            >
              Submit
            </button>
          </div>
        </div>
        <div className={styles.displayContainer}>
          <h3>Answer</h3>
          <div className={styles.displayBox}>
            <div className="loader-wrapper">{loading && <Loader />}</div>
            {responseValue && <div>{responseValue}</div>}
          </div>
        </div>
        <footer> © RV Matrix Software Technologies Pvt Ltd</footer>
      </div>
      
      <div className={styles.menuButton} onClick={handleMenuToggle}>
        <div className={styles.menuIcon}></div>
      </div>
      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          <p onClick={handleprivacyClick}>Privacy Policy</p>
          <p onClick={handleTermsClick}>Terms and Conditions</p>
          <p onClick={handleCancellationClick}>Cancellation & Refund Policy</p>
          <p onClick={handleContactUsClick}>Contact Us</p>
          <p onClick={handlebuyNowClick}>Subscription</p>
          <p onClick={handleBackClick}>Logout</p>
        </div>
      )}
    
   
    </section>
    
  );
}

export default BiblePage;
