import axios from "axios";
import { baseUrl } from "../config/apiUrlConfig";
var FormData = require('form-data');


const genOtp = async (phoneNumber) => {
    var data = new FormData();

    data.append('phoneNumber', phoneNumber);
    const response = await axios.post(
        baseUrl+"/generateOtp",
        data
    );
    return response.data;
}

const verifyOtp = async (inputValue) => 
{
    var data = new FormData();
    data.append('phoneNumber', inputValue.phonenumber);
    data.append('otp', inputValue.otp);    
    const response = await axios.post(
        baseUrl+"/verifyotp",
        data
    );

    return response.data

}

const createUser = async (inputValue) => {

    var data = new FormData();
    data.append('userName', inputValue.name);
    data.append('phoneNumber', inputValue.phonenumber);
    data.append('password', inputValue.password);
    data.append('age', inputValue.age);
    data.append('serviceID', 1);

    const response = await axios.post(
        baseUrl+"/signup",
        data
    );

    return response.data;
}

export {genOtp,verifyOtp, createUser}