import axios from "axios";
import { baseUrl } from "../config/apiUrlConfig";
var FormData = require('form-data');

const bibleAPi = async (inputValue,userData) => {
    try {
        const formData = new FormData();
        formData.append("question", inputValue);
        formData.append("username", userData);
        console.log("Posting...")
        const response = await axios.post(
            baseUrl+"/bible",
            // "http://127.0.0.1:5000/bible",
            formData
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};



export { bibleAPi};