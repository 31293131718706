import axios from "axios";
import { baseUrl } from "../config/apiUrlConfig";
var FormData = require('form-data');


const loginUser = async (inputValue) => {
    try
    {
        var data = new FormData();
        data.append('phoneNumber', inputValue.phonenumber);
        data.append('password', inputValue.password);
        data.append('serviceID',1);
        const response = await axios.post(
            baseUrl+"/login",
            data
        );
        return response.data;
    }
    catch (error)
    {
        console.error(error);
    }
}



export {loginUser}

